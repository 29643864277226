.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.react-toggle--checked .react-toggle-track {
  background-color: #2199e8;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1d85c9;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #2199e8;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#main-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1000;
}
